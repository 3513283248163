import about from "../assets/nav/about.png";
import game from "../assets/nav/game.png";
import navRoadmap from "../assets/nav/roadmap.png";
import team from "../assets/nav/team.png";

import teamheading from "../assets/headings/team.png";
import partnersheading from "../assets/headings/partners.png";

import hero from "../assets/hero.png";

import frame from "../assets/frame.png";
import whocares from "../assets/who-cares.gif";

import minigame from "../assets/game.png";
import abeben from "../assets/abe-ben.png";

import roadmap from "../assets/roadmap.png";

import wiz from "../assets/team/wiz.png";
import defacer from "../assets/team/defacer.png";
import juice from "../assets/team/juice.png";

import discord from "../assets/socials/discord.png";
import twitter from "../assets/socials/twitter.png";

import nftlab from "../assets/nftlab.svg";
import { useRef } from "react";

const Home = () => {
  const aboutR = useRef();
  const roadmapR = useRef();
  const teamR = useRef();

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container mx-auto px-4 lg:px-20 py-16 space-y-24 bg-green-200 font-sans italic font-bold">
      <nav className="flex py-5 px-8 bg-green-100 border-8 rounded-xl items-center space-x-4 lg:space-x-16">
        <button onClick={() => scrollTo(aboutR)}>
          <img alt="about" src={about} />
        </button>
        <a href="https://heartfelt-boba-796e96.netlify.app/">
          <img alt="game" src={game} />
        </a>
        <button onClick={() => scrollTo(roadmapR)}>
          <img alt="roadmap" src={navRoadmap} />
        </button>
        <button onClick={() => scrollTo(teamR)}>
          <img alt="team" src={team} />
        </button>
      </nav>
      <header className="flex flex-col items-center justify-center">
        <img className="" alt="hero" src={hero} />
        <div className="my-24 flex justify-center space-x-8">
          <a href="https://discord.gg/Q4Z3fRwH4N" target="_blank" rel="noreferrer">
            <img className="h-24 w-24" src={discord} alt="discord" />
          </a>
          <a href="https://twitter.com/DefaceNft" target="_blank" rel="noreferrer">
            <img className="h-24 w-24" src={twitter} alt="twitter" />
          </a>
        </div>
      </header>
      <main className="space-y-40">
        <section
          ref={aboutR}
          className="flex flex-col lg:flex-row space-y-12 lg:space-y-0 lg:space-x-24 items-center justify-between"
        >
          <img alt="framed ben" className="w-1/2" src={frame} />
          <div className="text-center text-xl">
            <h2 className="mb-16 text-4xl text-red-600 font-display leading-loose">The Story of Deface</h2>
            <p className="mb-6">
              When the idea behind the art was decided on, we knew we'd struck gold... digitally speaking. While it
              is technically a PFP project, we also wanted it to have a unique utility for the community and thus
              the NFT University, Merch plan, mini game, and weekly comics plan was born.
            </p>

            <p>
              The Defacer is juxtaposing the idea of old world money and new world money by showing defaced Ben
              Franklins with internet and pop culture icons throughout the body of work. Ben Franklin was an astute
              student and teacher, and kind of a rebel, as well as being the most culturally iconic of all the
              presidential monies today. We figured messing with his face was a good idea and he would probably
              approve were he still alive.
            </p>
          </div>
        </section>
        <section className="flex justify-center items-center">
          <img alt="who cares" src={whocares} />
        </section>
        <section className="flex flex-col lg:flex-row items-center justify-between space-y-12 lg:space-y-0 lg:space-x-32">
          <a className="lg:w-1/2" href="https://heartfelt-boba-796e96.netlify.app/">
            <img alt="minigame" src={minigame} />
          </a>
          <img alt="abe and ben" className="lg:w-1/2 -mx-16" src={abeben} />
        </section>
        <section ref={roadmapR} className="flex justify-center items-center">
          <img alt="roadmap" className="w-3/4" src={roadmap} />
        </section>
        <section ref={teamR} className="space-y-16">
          <div className="py-10 px-16 bg-green-100 border-8 rounded-xl flex items-center justify-center space-x-16">
            <img alt="team" src={teamheading} />
          </div>
          <div className="grid grid-rows-3 lg:grid-rows-none lg:grid-cols-3 gap-16 text-center">
            <div className="flex flex-col">
              <div className="">
                {" "}
                <h3 className="text-5xl mb-6">the wiz</h3>
                <img alt="wiz" className="border-8 rounded-xl mb-12" src={wiz} />
                <h4 className="text-green-300 text-3xl mb-12 px-4">cyber security specialist</h4>
              </div>
              <p className="text-2xl flex lg:items-center flex-grow">
                oversees security and accounts. makes sure all our asses are covered come game time.
              </p>
            </div>

            <div className="flex flex-col">
              <div className="">
                {" "}
                <h3 className="text-5xl mb-6">the defacer</h3>
                <img alt="defacer" className="border-8 rounded-xl mb-12" src={defacer} />
                <h4 className="text-green-300 text-3xl mb-12 px-4">
                  artist, nft degen, and all-around criminal mastermind
                </h4>
              </div>
              <p className="text-2xl flex lg:items-center flex-grow">
                artist & creator, founder, defacer of all the things. JOIN ME IN OUR DISCORD TO HELP ME RUN THE
                WORLD.
              </p>
            </div>

            <div className="flex flex-col">
              <div className="">
                {" "}
                <h3 className="text-5xl mb-6">the juice</h3>
                <img alt="juice" className="border-8 rounded-xl mb-12" src={juice} />
                <h4 className="text-green-300 text-3xl mb-12 px-4">social media dev</h4>
              </div>
              <p className="text-2xl flex lg:items-center flex-grow">
                oversees our collabs, twitter, ig, and tiktok pages
              </p>
            </div>
          </div>
          <p className="text-6xl text-center py-12">find us doxxed in the discord!</p>
        </section>
        <section className="space-y-16">
          <div className="py-10 px-4 lg:px-16 bg-green-100 border-8 rounded-xl flex items-center justify-center space-x-16">
            <img alt="partners" src={partnersheading} />
          </div>

          <img alt="nftlab" className="w-1/2 mx-auto" src={nftlab} />
          <p className="text-center text-xl">
            First class, full-service blockchain development - follow{" "}
            <a className="underline" href="https://twitter.com/NFTLabTeam" target="_blank" rel="noreferrer">
              @nftlabteam
            </a>{" "}
            on Twitter!
          </p>
        </section>
      </main>
      <footer></footer>
    </div>
  );
};

export default Home;
