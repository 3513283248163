const Game = () => {
  return (
    <>
      <canvas id="canvas" width="960" height="540"></canvas>
      <script src="../assets/pixel_dungeon_data/func.js?v=10" charset="utf-8"></script>
      <script src="../assets/pixel_dungeon_data/game.js?v=1" charset="utf-8"></script>
    </>
  );
};

export default Game;
